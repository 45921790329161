import ValidatorMixin from "@/components/form/ValidatorMixin";
import SpecificationValidator from "@/components/auth/data_block/SpecificationValidator";

export default {
  mixins: [
    ValidatorMixin,
    SpecificationValidator,
  ],
  data() {
    return {
      sharedDataKeys: [],
      specifications: [],
    }
  },
  computed: {
    specificationsMap() {
      return this.specifications.reduce(
        (acc, item) => ({...acc, [item.id]: item }),
        {}
      );
    },
    sharedDataKeysOptions() {
      return this.sharedDataKeys.map((item) => ({
        value: {...item, specification: this.specificationsMap[item.specificationId]},
        label: item.key,
        key: item.key
      }));
    },
  },
  methods: {
    getValueValidator({ specificationId }) {
      if (specificationId) {
        const specification = this.specificationsMap[specificationId];

        if (specification) {
          return this.getSpecificationValidator(specification).validator;
        }
      }
    },
    getValuePlaceholder({ specificationId }) {
      if (specificationId) {
        const specification = this.specificationsMap[specificationId];

        if (specification) {
          return this.getSpecificationValidator(specification).placeholder;
        }
      }
    },
    fetchDataKeys() {
      this.$raaDataProvider.getList('dataKeys', { size: 999 })
          .then(({content}) => {
            this.sharedDataKeys = content.filter(item => !item.deletedAt);
          })
          .catch((error) => this.notifyError(error.message));
    },
    fetchSpecifications() {
      this.$raaDataProvider.getList('specifications')
          .then(({content}) => {
            this.specifications = content;
          })
          .catch((error) => this.notifyError(error.message))
    },
  },
  mounted() {
    Promise.all([
      this.fetchSpecifications(),
      this.fetchDataKeys(),
    ]);
  }
};
