<template>
  <Form ref="form" :submit="onSubmit">
    <template v-slot="props">
      <div class="row-template">
        <div class="form-row">
          <div class="field-key">
            <div class="flex items-center">
              <SelectInput
                name="key"
                placeholder="shared data key"
                class="pr-1"
                :options="dataKeyOptions"
                :validate="required"
                optionLabel="label"
                objectMode
              />
              <specification-info
                v-if="keySelected"
                :properties="currentSpecification.properties"
                :isNullable="currentSpecification.isNullable"
              />
              <Tooltip v-else icon="info" text="select a data key" />
            </div>
          </div>
          <div class="field-value">
            <TextField
              :key="specificationName"
              name="value"
              :placeholder="valuePlaceholder"
              :validate="valueValidator"
              clearable
            />
          </div>
        </div>
        <row-template-actions class="form-row justify-end" @cancel="$emit('cancel')" :disabled="props.invalid" />
      </div>
    </template>
  </Form>
</template>

<script>
  import {isEmpty} from 'lodash-es';
  import NotifyMixin from '@/mixins/NotifyMixin';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Form from '@/components/form/Form';
  import TextField from '@/components/form/TextField';
  import SelectInput from '@/components/form/SelectInput';
  import Tooltip from '@/components/ui/Tooltip';
  import RowTemplateActions from '@/components/ui/inlineediting/RowTemplateActions';
  import SpecificationValidator from '@/components/auth/data_block/SpecificationValidator';
  import SpecificationInfo from '@/components/auth/data_block/SpecificationInfo';

  export default {
  name: 'DataRecordForm',
  mixins: [NotifyMixin, ValidatorMixin, SpecificationValidator],
  components: {
    Tooltip,
    Form,
    TextField,
    SelectInput,
    RowTemplateActions,
    SpecificationInfo,
  },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    dataKeyOptions: {
      type: Array,
      default: () => []
    },
  },
  emits: ['cancel'],
  computed: {
    keySelected() {
      return !isEmpty(this.currentSpecification);
    },
    specificationName() {
      return this.currentSpecification.name || 'specification';
    }
  },
  data() {
    return {
      currentSpecification: {},
      valuePlaceholder: 'value',
      valueValidator: undefined,
    }
  },
  mounted() {
    this.$watch(
      () => this.$refs.form?.formState.values.key,
      (key) => {
        const specification = key?.value?.specification;
        if (!specification) {
          return;
        }
        const { placeholder, validator } = this.getSpecificationValidator(specification);
        this.currentSpecification = specification;
        this.valuePlaceholder = placeholder;
        this.valueValidator = validator;
      }, {
        deep: true,
      }
    );
  },
};
</script>

<style scoped>
  .row-template :deep(.field-key) {
    @apply pl-4 pr-2;
    width: 40%;
  }
  .row-template :deep(.field-value) {
    @apply pl-2 pr-4;
    width: 60%;
  }
</style>
