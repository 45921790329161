<template>
    <div class="flex">
        <button class="btn-primary btn-solid btn-small" :disabled="disabled">
            add
        </button>
        <button
            class="btn-action-warning focus:outline-none"
            type="button"
            @click.stop="$emit('cancel')"
        >
            <icon class="w-5 h-5 mx-4" name="trash"/>
        </button>
    </div>
</template>

<script>
    import Icon from "@/components/ui/Icon";

    export default {
        name: "RowTemplateActions",
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['cancel'],
        components: {
            Icon,
        },
    }
</script>

<style scoped>

</style>
