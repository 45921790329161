<script>
  import { i18n } from '@/i18n';
  import ValidatorMixin from "@/components/form/ValidatorMixin";

  export default {
    mixins: [
      ValidatorMixin,
    ],
    data() {
      return {
        validators: {
          AllowedValues: (constraint) => (value) => {
            const allowedValues = constraint.split('|');
            return value && allowedValues.includes(value) ? undefined : i18n.global.t('validation.invalid_value')
          },
          AllowedPattern: (constraint) => (value) => {
            const regex = new RegExp(constraint);
            return value && regex.test(value) ? undefined : i18n.global.t('validation.invalid_value');
          },
          MaxValue: (constraint) => isNaN(constraint) ? undefined : this.maxValue(Number(constraint)),
          MinValue: (constraint) => isNaN(constraint) ? undefined : this.minValue(Number(constraint)),
          MaxLength: (constraint) => isNaN(constraint) ? undefined : this.maxLength(Number(constraint)),
          MinLength: (constraint) => isNaN(constraint) ? undefined : this.minLength(Number(constraint)),
        },
      }
    },
    methods: {
      getSpecificationValidator({ type, isNullable, properties }) {
        const validators = [];

        if (!isNullable) {
          validators.push(this.required);
        }

        if (type === 'number') {
          validators.push(this.number);
        }

        for (const [key, value] of Object.entries(properties)) {
          if (this.validators[key]) {
            validators.push(this.validators[key](value));
          }
        }

        let commonValidator = this.composeValidators(...validators);

        if (type === 'list_strings' || type === 'list_numbers') {
          commonValidator = this.getListValidator(commonValidator);
        }

        const { ConstraintDescription, Default } = properties;

        return {
          validator: commonValidator,
          placeholder: ConstraintDescription || (Default && `e.g. ${Default}`) || `value of type ${type}`,
        }
      },
      getListValidator(composedValidator) {
        return (value, values, meta) =>
            value?.split(',').reduce((acc, item) => acc || composedValidator(item, values, meta), undefined);
      },
    }
  };
</script>
