<template>
  <th
    v-if="isHeader"
    :class="`vuetable-th-component-${fieldName}`"
    v-html="title"
  />
  <td v-else :class="`vuetable-td-component-${fieldName}`">
    <editable-field
      ref="field"
      :initialValues="{ value: getValue }"
      editClass="mb-1"
      :on-submit="update"
      :close-on-submit="closeOnSubmit"
      :no-click-outside="noClickOutside"
    >
      <template v-slot:field="{ editMode, formProps }">
        <div class="flex">
          <div class="flex-1">
            <TextField
              name="value"
              :editMode="editMode"
              textClass="text-sm"
              size="small"
              :placeholder="placeholder"
              :validate="fieldValidator"
              :clearable="editMode && Boolean(formProps.values.value)"
            />
          </div>
          <clipboard-button
            v-if="clipboard"
            class="focus:outline-none px-2"
            :class="editMode ? 'ml-0 mr-2' : 'ml-2 opacity-0'"
            :value="textToCopy"
          ></clipboard-button>
        </div>
      </template>
    </editable-field>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import TextField from "@/components/form/TextField";
  import EditableField from "@/components/ui/inlineediting/EditableField";
  import ClipboardButton from "@/components/auth/list/ClipboardButton";

  export default {
    name: 'TextEditableField',
    components: {ClipboardButton, TextField, EditableField},
    mixins: [VuetableFieldMixin, ValidatorMixin],
    data() {
      return {
        textToCopy: '',
      }
    },
    computed: {
      fieldName() {
        return this.rowField.switch?.fieldName || 'description';
      },
      getValue() {
        const getValue = this.rowField.switch?.getValue;
        return getValue ? getValue(this.rowData) : this.rowData[this.fieldName];
      },
      fieldValidator() {
        const getValidator = this.rowField.switch?.getValidator;
        return getValidator ? getValidator(this.rowData) : undefined;
      },
      placeholder() {
        const getPlaceholder = this.rowField.switch?.getPlaceholder;
        return getPlaceholder ? getPlaceholder(this.rowData) : '';
      },
      clipboard() {
        return this.rowField.switch?.clipboard;
      },
      closeOnSubmit() {
        return typeof this.rowField.switch?.onUpdate !== 'function';
      },
      noClickOutside() {
        return this.rowField.switch?.noClickOutside;
      },
    },
    methods: {
      update({ value }) {
        const onUpdate = this.rowField.switch?.onUpdate;

        if (typeof onUpdate === 'function') {
          onUpdate(value, { data: this.rowData, index: this.rowIndex, ref: this.$refs.field});
        } else {
          this.rowData[this.fieldName] = value;
        }
      },

    },
    mounted() {
      this.$watch(
        () => this.$refs.field?.innerState?.values.value,
        value => {
          if (this.clipboard) {
            this.textToCopy = value;
          }
        }, {
          immediate: true,
        }
      )
    }
  };
</script>
<style scoped>
  .editable-field:hover :deep(.clipboard-button) {
    @apply opacity-100;
  }
</style>
