<template>
  <Tooltip icon="info" :positionX="positionX">
    <div class="text-xs">
      <span class="italic">{{isNullable ? 'null is allowed' : 'value is required'}}</span>
      <ul v-if="hasProperties" class="mt-2">
        <li v-for="(val, key) in properties" :key="key" class="break-words">{{key}}: {{val}}</li>
      </ul>
    </div>
  </Tooltip>
</template>

<script>
  import {isEmpty} from 'lodash-es';
  import Tooltip from "@/components/ui/Tooltip";

  export default {
    name: 'SpecificationInfo',
    props: {
      properties: {
        type: Object,
        default() {
          return {};
        },
      },
      isNullable: {
        type: Boolean,
        default: false,
      },
      positionX: {
        type: String,
        default: 'left',
      },
    },
    components: {
      Tooltip,
    },
    computed: {
      hasProperties() {
        return !isEmpty(this.properties);
      }
    }
  };
</script>

<style scoped>

</style>
